import "./TopHeader.css";
import Logo from "../../../assets/images/AP-WebsiteLogo-Main.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { IoSearchSharp } from "react-icons/io5";
import MenuImg from "../../../assets/images/toggle_pic.png";
import { useState } from "react";

function TopHeader() {
  const [isMenuOpn, setIsMenuOpn] = useState(false);
  console.log("isMenuOpn", isMenuOpn);
  return (
    <>
      <section className="topHeaderWrapper">
        <div className={isMenuOpn ? "topHeader movedHeader" : "topHeader"}>
          <Navbar expand="lg" className="bg-body-tertiary headerContent">
            <Container>
              <Navbar.Toggle
                onClick={() => setIsMenuOpn(!isMenuOpn)}
                aria-controls="basic-navbar-nav"
              >
                MENU <img src={MenuImg} />
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="https://www.africaprint.com">Home</Nav.Link>
                  <Nav.Link href="https://www.africaprint.com/news/">
                    NEWS
                  </Nav.Link>
                  <Nav.Link href="https://www.africaprint.com/live/">
                    LIVE
                  </Nav.Link>
                  <Nav.Link href="https://africaprintexpo.com" target="_blank">
                    EXPOS
                  </Nav.Link>
                  <Nav.Link href="https://hub.africaprint.com/deals/companies">
                    DIRECTORY
                  </Nav.Link>
                  <Nav.Link href="https://hub.africaprint.com/classifieds">
                    BUY & SELL
                  </Nav.Link>
                  <Nav.Link href="https://hub.africaprint.com/job-types">
                    JOBS
                  </Nav.Link>
                  <Nav.Link href="https://www.africaprint.com/subscribe/">
                    SUBSCRIBE
                  </Nav.Link>
                  <Nav.Link href="https://www.africaprint.com/calendar/">
                    EVENTS
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className={isMenuOpn ? "logoImgMargin" : "logoImg"}>
          <img src={Logo} alt="news-logo" />
        </div>
      </section>
    </>
  );
}

export default TopHeader;
