import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  BOOK_TYPE,
  SERACH_REFRENCE_TYPE,
  WEB_LIST_TYPE,
} from "../../utils/Constants";
import ClassifiedCategoryList from "../ClassiFieds/ClassifiedCategoryList";
import DealList from "../DealModule/DealList/DealList.js";
import CompanyDataModule from "../DealTypesModule/CompanyDataModule.js";
import { useTranslation } from "react-i18next";
import Loader from "../../utils/Loader/Loader";
import CustomBtn from "../../formComponent/Button/Button";
import { useNavigate } from "react-router-dom";
import { guestUserLogin, userLogout } from "../../store/slices/UserSlice";
import { searchListApi } from "../../store/slices/SearchSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import { PAGINATION_VALUE } from "../../utils/Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../assets/images/dots-loading.gif";

function SearchList() {
  const { userToken } = useSelector((state) => state.user);
  const { searchList, isLoading, searchValue, searchTotalCount } = useSelector(
    (state) => state.search
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(PAGINATION_VALUE.DEFAULT_LIMIT);


  // ====manage load more pagination and calling api of search====
  function Loadmore(isLoadmore) {
    if (searchList.length >= searchTotalCount) {
      return false;
    }
    setOffset(offset + PAGINATION_VALUE.DEFAULT_LIMIT);
    const searchValues = {
      limit: PAGINATION_VALUE.DEFAULT_LIMIT,
      offset: offset,
      search: searchValue,
    };
    dispatch(
      searchListApi({
        userToken: userToken,
        searchValues,
        Loadmore: isLoadmore,
      })
    ).then(async (responsejson) => {
      let response = responsejson.payload.response;
      if (response.status_code) {
        if (response.status_code === STATUS_CODES.INVALID_TOKEN) {
          dispatch(userLogout(userToken)).then(() => {
            dispatch(guestUserLogin());
            navigate("/login");
          });
        }
      } else {
        if (response.status === STATUS_CODES.INVALID_TOKEN) {
          dispatch(userLogout(userToken)).then(() => {
            dispatch(guestUserLogin());
            navigate("/login");
          });
        }
      }
    });
  }

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <section className="main">
        <Container className="screenOverride">
          <div className="searchModuleBox">
            <Row>
              <Col xs={12} sm={12} md={12} lg={6}>
                <InfiniteScroll
                  dataLength={offset + PAGINATION_VALUE.DEFAULT_LIMIT}
                  next={() => {
                    if (searchList.length < searchTotalCount) {
                      setTimeout(() => {
                        Loadmore(true);
                      }, 1500);
                    }
                  }}
                  style={{ overflow: "inherit" }}
                  hasMore={searchList.length >= searchTotalCount ? false : true}
                  scrollThreshold="1100px"
                  loader={
                    searchList.length < searchTotalCount && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "14px",
                        }}
                      >
                        <img
                          style={{ width: "60px" }}
                          src={Loading}
                          alt="loading"
                        />
                      </div>
                    )
                  }
                >
                  {searchList
                    ? searchList.map((item, index) => {
                        if (item.refrence_type == SERACH_REFRENCE_TYPE.DEAL) {
                          return (
                            <div className="searchedComponent" key={index}>
                              <DealList
                                fromDeal={true}
                                dealList={item.detail}
                                deal={true}
                              />
                            </div>
                          );
                        } else if (
                          item.refrence_type == SERACH_REFRENCE_TYPE.COMPANY
                        ) {
                          return (
                            <div className="searchedComponent" key={index}>
                              <CompanyDataModule
                                company={true}
                                companyListValue={item.detail[0]}
                                companyShowDate={item}
                              />
                            </div>
                          );
                        } else if (
                          item.refrence_type == SERACH_REFRENCE_TYPE.CLASSIFIED
                        ) {
                          return (
                            <div className="searchedComponent" key={index}>
                              <ClassifiedCategoryList
                                searchIndex={index}
                                // displayRoute="your_advert"
                                forSaleListData={item.detail}
                                bookType={BOOK_TYPE.CLASSIFIED}
                                webListType={WEB_LIST_TYPE.SEARCH}
                              />
                            </div>
                          );
                        }
                      })
                    : ""}
                </InfiniteScroll>
                {/* {searchList.length >= searchTotalCount ? (
                  ""
                ) : (
                  <div className="marginTop">
                    {" "}
                    <CustomBtn
                      children={t("LOAD_MORE")}
                      onClick={() => {
                        Loadmore(true);
                        setOffset(offset + PAGINATION_VALUE.DEFAULT_LIMIT);
                      }}
                      type={"button"}
                    />{" "}
                  </div>
                )} */}
              </Col>
            </Row>
          </div>
        </Container>
        {searchList.length == 0 && (
          <h4 className="displayNoText">{t("NO_SEARCH_DATA")}</h4>
        )}
      </section>
    </>
  );
}
export default SearchList;